import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useStaticImprint = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.queryImprintContents.0";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useImprintQuery {
    squidex {
      queryImprintContents(filter: "status eq 'Published'") {
        id
        data {
          metaTitle {
            de
            en
            fr
          }
          metaDescription {
            de
            en
            fr
          }

          content {
            de
            en
            fr
          }
        }
      }
    }
  }
`;
