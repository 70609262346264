import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useStaticHomepage = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.queryHomepageContents.0";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useHomepageQuery {
    squidex {
      queryHomepageContents(filter: "status eq 'Published'") {
        id
        data {
          metaTitle {
            de
            en
            fr
          }
          metaDescription {
            de
            en
            fr
          }

          gallery {
            de {
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 675, quality: 64, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
            }
            en {
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 675, quality: 64, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
            }
            fr {
              caption
              image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 675, quality: 64, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
            }
          }

          sections {
            de {
              id
              title
              text
              slides: images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 875, maxHeight: 656, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              linkUrl
              linkText
              imageTitle
              imageCaption
            }
            en {
              id
              title
              text
              slides: images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 875, maxHeight: 656, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              linkUrl
              linkText
              imageTitle
              imageCaption
            }
            fr {
              id
              title
              text
              slides: images {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 875, maxHeight: 656, quality: 64) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                ...SquidexImageLocalFileProps
              }
              linkUrl
              linkText
              imageTitle
              imageCaption
            }
          }
        }
      }
    }
  }
`;
