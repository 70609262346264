import { graphql, useStaticQuery } from "gatsby";

import { useFlatten, getProperty } from "@lachevaliniere/shared";

export const useStaticShared = () => {
  const results = useStaticQuery(query) || {};
  const key = "squidex.querySharedContents.0";

  return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
  query useSharedQuery {
    squidex {
      querySharedContents(filter: "status eq 'Published'") {
        id
        data {
          metaCompany {
            de
            en
            fr
          }
          metaPage {
            de
            en
            fr
          }

          notFoundTitle {
            de
            en
            fr
          }
          notFoundText {
            de
            en
            fr
          }
          notFoundButton {
            de
            en
            fr
          }
        }
      }
    }
  }
`;
